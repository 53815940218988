import { useState, useEffect } from "react"

const useSwipeDirection = () => {
  const [swipeDirection, setSwipeDirection] = useState("")

  let xDown = null
  let yDown = null

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart)
    window.addEventListener("touchmove", handleTouchMove)
    return () => {
      window.removeEventListener("touchstart", handleTouchStart)
      window.removeEventListener("touchmove", handleTouchMove)
    }
  })

  const handleTouchStart = e => {
    const firstTouch = e.touches[0]
    xDown = firstTouch.clientX
    yDown = firstTouch.clientY
  }

  const handleTouchMove = e => {
    if (!xDown || !yDown) {
      return
    }

    var xUp = e.touches[0].clientX
    var yUp = e.touches[0].clientY

    var xDiff = xDown - xUp
    var yDiff = yDown - yUp

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        setSwipeDirection("left")
      } else {
        setSwipeDirection("right")
      }
    } else {
      if (yDiff > 0) {
        setSwipeDirection("up")
      } else {
        setSwipeDirection("down")
      }
    }
    /* reset values */
    xDown = null
    yDown = null
  }

  return swipeDirection
}

export default useSwipeDirection
