import React from "react"
import PropTypes from "prop-types"
import "./drawer.less"

import { useSpring, animated, config } from "react-spring"

const Drawer = ({ isOpen, children }) => {
  const drawerSpring = useSpring({
    width: isOpen ? "70vw" : "0vw",
    config: config.stiff,
  })

  return (
    <animated.section className="drawer" style={drawerSpring}>
      {children}
    </animated.section>
  )
}

Drawer.propTypes = {
  isOpen: PropTypes.bool,
}

export default Drawer
