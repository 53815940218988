import React from "react"
import PropTypes from "prop-types"
import "./switch.less"

const Switch = ({ disabled, children, title, ...switchProps }) => {
  return (
    <div className="switch">
      <input id={title} name={title} type="checkbox" {...switchProps} />
      <label for={title} />
    </div>
  )
}

Switch.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default Switch
