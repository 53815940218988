import React from "react"
import PropTypes from "prop-types"
import "./button.less"

const Button = ({ disabled, primary, children, title, ...buttonProps }) => {
  return (
    <button
      disabled={disabled}
      className={`${primary ? "primary" : ""}`}
      aria-label={title}
      {...buttonProps}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Button
