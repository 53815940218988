import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import "./hero.less"

const Hero = ({ children, cover, fade }) => {
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    setScroll(window.pageYOffset)
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleScroll = () => {
    setScroll(window.pageYOffset)
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "pluto.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <section className="hero" style={{ transform: `translateY(${scroll * 0.4}px)` }}>
          {cover ? (
            <Img className={`cover ${fade ? "fade" : ""}`} fluid={cover.childImageSharp.fluid} />
          ) : (
            <Img className={`cover ${fade ? "fade" : ""}`} fluid={data.placeholderImage.childImageSharp.fluid} />
          )}
          <div className="content">{children}</div>
        </section>
      )}
    />
  )
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  cover: PropTypes.object,
  fade: PropTypes.bool,
}

export default Hero
