import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { ThemeProvider } from "../../context/theme"

import Footer from "../footer/footer"
import Header from "../header/header"
import Hero from "../hero/hero"
import Drawer from "../drawer/drawer"
import Nav from "../nav/nav"
import "./layout.less"

import useSwipeDirection from "../../hooks/useSwipeDirection"

const Layout = ({ children, heroCover, heroContent, heroFade }) => {
  const swipeDirection = useSwipeDirection()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [footerHeight, setFooterHeight] = useState("30vh")

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    setFooterHeight(`${document.querySelector("footer").offsetHeight}px`)
    setIsDrawerOpen(swipeDirection === "right" ? true : false)
    return () => window.removeEventListener("resize", handleResize)
  }, [swipeDirection, footerHeight])

  const toggleDrawer = () => {
    setIsDrawerOpen(isDrawerOpen ? false : true)
  }

  const handleResize = () => {
    setFooterHeight(`${document.querySelector("footer").offsetHeight}px`)
  }

  return (
    <ThemeProvider>
      <div id="wrap" className="wrapper">
        <Header isHero={heroCover || heroContent ? true : false}>
          <Nav hamburgerClick={toggleDrawer} isOpen={isDrawerOpen} />
        </Header>
        <div className="header-spacer" />
        {heroCover || heroContent ? (
          <>
            <Hero cover={heroCover} fade={heroFade}>
              {heroContent}
            </Hero>
            <div className="hero-spacer" />
          </>
        ) : (
          ""
        )}
        <Drawer isOpen={isDrawerOpen}>
          <Nav hamburgerClick={toggleDrawer} isOpen={true} />
        </Drawer>
        <main>{children}</main>
        <div className="footer-spacer" style={{ height: `${footerHeight}` }} />
        <Footer />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  heroCover: PropTypes.object,
  heroContent: PropTypes.node,
  heroFade: PropTypes.bool,
}

export default Layout
