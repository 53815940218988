import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "./header.less"

const Header = ({ children, isHero }) => {
  const [isTop, setTop] = useState(true)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleScroll = () => {
    window.pageYOffset === 0 ? setTop(true) : setTop(false)
  }

  return <header className={`${isTop ? "top" : ""} ${isHero ? "header-hero" : ""}`}>{children}</header>
}

Header.propTypes = {
  isHero: PropTypes.bool,
}

export default Header
