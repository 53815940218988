import React from "react"
import { Link } from "gatsby"
import "./footer.less"

import Nav from "../nav/nav"
import Button from "../ui/button/button"

const Footer = () => (
  <footer>
    <section>
      <Nav>
        <h2>Links</h2>
      </Nav>
      <div className="contact">
        <h2>Contact</h2>
        <Link to="/contact">Contact me</Link>
        <a href="https://github.com/Haradrim">Github</a>
      </div>
      <div className="about">
        <h2>About</h2>
        <Link to="/#about">About me</Link>
        <p>
          My name is <span>Sander Vervaeke</span> and I’m a <span>full stack web developer</span> from <span>Kortrijk, Belgium</span>. I’m very passionate about
          programming and making things in general. Currently I work as a <span>mobile, web and IoT consultant at delaware</span>. Besides programming and being
          a maker, I also really enjoy cooking and fencing.
        </p>
        <Button
          title={"back to top"}
          className={"link"}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }}
        >
          Back to top
        </Button>
      </div>
      <div className="copy1">
        <p className="text-disabled">Copyright © {new Date().getFullYear() || "2020"}, Sander Vervaeke</p>
      </div>
      <div className="copy2">
        <p className="text-disabled">
          Coded and deployed by me with{" "}
          <span role="img" aria-label="heart">
            ❤️
          </span>
        </p>
      </div>
    </section>
  </footer>
)

export default Footer
