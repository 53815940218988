import React, { useContext } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./nav.less"

import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

import { ThemeContext } from "../../context/theme"

import Logo from "../../components/ui/logo/logo"
import Button from "../../components/ui/button/button"
import Switch from "../../components/ui/switch/switch"

const Nav = ({ hamburgerClick, isOpen, children }) => {
  const [theme, setTheme] = useContext(ThemeContext)

  const handleSwitchChange = e => {
    setTheme(e.target.checked ? "light" : "dark")
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <nav>
          {children}
          <Button
            onClick={hamburgerClick}
            className="hamburger link"
            title="hamburger"
          >
            {isOpen ? (
              <FontAwesomeIcon icon={faTimes} size="2x" />
            ) : (
              <FontAwesomeIcon icon={faBars} size="2x" />
            )}
          </Button>
          <Link to="/" className="logo" aria-label="logo Sander Vervaeke">
            <Logo />
          </Link>
          <Link to="/#about">About</Link>
          <Link to="/#tech">Tech</Link>
          <Link to="/#experience">Experience</Link>
          <Link to="/#projects">Projects</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/contact">Contact</Link>
          <Switch
            title="theme"
            onChange={handleSwitchChange}
            checked={theme === "light" ? true : false}
          />
        </nav>
      )}
    />
  )
}

Nav.propTypes = {
  hamburgerClick: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default Nav
